import React from 'react'
import {Routes, Route, useLocation } from 'react-router-dom';
import Home from "../pages/Home";
import About from "../pages/About";
import Career from "../pages/Career";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import Faq from "../pages/Faq";
import Team from "../pages/Team";
import Usersecurity from "../pages/Usersecurity";
import Contact from "../pages/Contact";
import {AnimatePresence} from 'framer-motion'
import Airtime from '../pages/Airtime';


function AnimatedRoutes() {
    const location= useLocation()
  return (
   
    <AnimatePresence>
        
          <Routes location={location} key={location.pathname}>
                <Route path='' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/career' element={<Career />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/team' element={<Team />} />
                <Route path='/usersecurity' element={<Usersecurity />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/airtime' element={<Airtime />} />
         </Routes>
    
    </AnimatePresence>
  

  )
}

export default AnimatedRoutes