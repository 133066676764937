import React from "react";
//import {Link} from 'react-router-dom';
//import card2 from '../img/Airtime.svg'

function Termscard(props) {
  return (
    <div
      className="Aboutcard"
      onClick={props.toggleall}
      onMouseEnter={props.toggleall}
    >
      <div className="about-container bg">
        <div className="about-text">
          <h2>Terms and Conditions</h2>
          <br></br>
          <h4>Updated 12/06/2023</h4>
          {/* { <div className='card-img'>
            <img src={card2} alt="Airtime and Data Topup" />
            </div> } */}

          <br></br>
          <br></br>

          <h4>Limitations Of Liability:</h4>
          <p>
            By downloading or using the platform, these terms will automatically
            apply to you – you should make sure therefore that you read them
            carefully before using the platform. You’re not allowed to copy, or
            modify the platform, any part of the platform in any way. You're not
            allowed to attempt to extract the source code of the platform, and
            you also shouldn't try to translate the platform into other
            languages, or make derivative versions. The platform itself, and all
            the database rights and other intellectual property rights related
            to it, still belong to Tribe. We are committed to ensuring that the
            platform is as useful and efficient as possible. For that reason, we
            reserve the right to make changes to the platform or to charge for
            its services, at any time and for any reason of which will be
            clearly communicated. We will never charge you for the platform or
            its services without making it very clear to you exactly what you’re
            paying for through subscription options. The Tribe platform stores
            and processes personal data that you have provided to us, in order
            to provide our Service. It's your responsibility to keep your phone
            and access to the platform secure. We therefore recommend that you
            do not jailbreak or root your phone, which is the process of
            removing software restrictions and limitations imposed by the
            official operating system of your device. It could make your phone
            vulnerable to malware/viruses/malicious programs, compromise your
            phone's security features and it could mean that the Tribe platform
            won't work properly or at all.
          </p>

          <h4>Terms And Conditions:</h4>
          <p>
            You should be aware that there are certain things that Tribe will
            not take responsibility for. Certain functions of the platform will
            require the platform to have an active internet connection. The
            connection can be Wi-Fi, or provided by your mobile network
            provider, but Tribe cannot take responsibility for the platform not
            working at full functionality if you don't have access to Wi-Fi, and
            you don't have any of your data allowance left. If you're using the
            platform outside of an area with Wi-Fi, you should remember that
            your terms of the agreement with your mobile network provider will
            still apply. As a result, you may be charged by your mobile provider
            for the cost of data for the duration of the connection while
            accessing the platform, or other third party charges. In using the
            platform, you're accepting responsibility for any such charges,
            including roaming data charges if you use the platform outside of
            your home territory (i.e. region or country) without turning off
            data roaming. If you are not the bill payer for the device on which
            you're using the platform, please be aware that we assume that you
            have received permission from the bill payer for using the platform.
            Along the same lines, Tribe cannot always take responsibility for
            the way you use the platform i.e. You need to make sure that your
            device stays charged – if it runs out of battery and you can't turn
            it on to avail the Service, Tribe cannot accept responsibility. With
            respect to Tribe's responsibility for your use of the platform, when
            you're using the platform, it's important to bear in mind that
            although we endeavour to ensure that it is updated and correct at
            all times, we do rely on third parties to provide information to us
            so that we can make it available to you. Tribe accepts no liability
            for any loss, direct or indirect, you experience as a result of
            relying wholly on this functionality of the platform. At some point,
            we may wish to update the platform.
          </p>

          <h4>Arbitration:</h4>
          <p>
            You and Tribe agree that any dispute arising out of or relating to
            this Agreement or the Services, shall be finally settled in binding
            arbitration, on an individual basis, in accordance with the Nigerian
            Arbitration Commision’s rules for arbitration of consumer-related
            disputes (accessible at https://www.nicarb.org). Subject to
            applicable jurisdictional requirements, consumer claimants
            (individuals whose transaction is intended for personal, family, or
            household use) may elect to pursue their claims in their local
            small-claims court rather than through arbitration so long as their
            matter remains in small claims court and proceeds only on an
            individual (non-class or non-representative) basis.
          </p>

          <h4>CLASS ACTION WAIVER:</h4>
          <p>
            TO THE EXTENT PERMISSIBLE BY LAW, ALL CLAIMS WILL ONLY BE BROUGHT ON
            AN INDIVIDUAL BASIS, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED CLASS, COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING
            (COLLECTIVELY “CLASS ACTION WAIVER”). THE ARBITRATOR MAY NOT
            CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY CLASS
            ARBITRATION. YOU ACKNOWLEDGE THAT, BY AGREEING TO THESE TERMS, YOU
            AND TRIBE ARE EACH WAIVING ANY RIGHT TO A TRIAL BY JURY AND YOU ARE
            WAIVING THE RIGHT TO PARTICIPATE IN A CLASS ACTION AGAINST TRIBE.
          </p>

          <h4>Changes to This Terms and Conditions:</h4>
          <p>
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Terms and
            Conditions on this page. These terms and conditions are effective as
            of June 12th, 2023.
          </p>

          <h4>Contact Us:</h4>
          <p>
            If you have any questions or suggestions about our Terms and
            Conditions, do not hesitate to contact us at support@tribewallet.co.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Termscard;
